@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.tip-text {
    animation: fadeIn 0.5s ease-in-out;
}