.camera:hover {
    transform: rotate(12deg);
}

@keyframes rotating {
    0% {
        opacity: 0.8;
        transform: rotate(0deg);
    }

    50% {
        opacity: 1;
        transform: rotate(-4deg);
    }

    100% {
        opacity: 0.8;
        transform: rotate(6deg);
    }
}

.camera {
    animation: rotating 1.7s infinite;
}